$(document).on("turbolinks:load", function() {
  $("#COPY").on("click", function() {
    element = document.getElementById("URI");
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
  });
});
