// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Polyfills for Internet Explorer Support
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

// JS Error Tracking with Sentry
import "shared/js_error_tracking";

import Rails from "rails-ujs";
import Turbolinks from "turbolinks";

import $ from "jquery";
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/tooltip";

import "../employer_portal/employer_portal.scss";
import "../employer_portal/two_factor_authentication.js";
import "../employer_portal/context.js";

// Initialize Rails UJS and Turbolinks
Rails.start();
Turbolinks.start();

const ready = () => {
  initFlashMessages();
  initTooltips();
};

$(document).on("turbolinks:load", ready);

const initTooltips = () => {
  // Initialize Bootstrap tooltips
  $('[data-toggle="tooltip"]').tooltip();
};

const initFlashMessages = () => {
  // Initialize Bootstrap alerts
  $(".alert").alert();
};